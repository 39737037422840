define("discourse/plugins/Discourse Adsense/discourse/components/adsense-block", ["exports", "discourse/lib/load-script"], function (_exports, _loadScript) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    visible: false,
    slotId: null,
    adStyle: null,
    init: function init() {
      this._super();

      var siteSettings = this.siteSettings;
      var slot = this.get('slot').trim();
      var position = slot.replace('_mobile', '');
      this.set('publisherCode', (siteSettings.adsense_publisher_code || '').trim());
      this.set('slotTrim', slot);
      var userSee = !this.currentUser || this.currentUser.get('trust_level') <= siteSettings.adsense_through_trust_level;

      if (userSee && siteSettings["adsense_show_".concat(position)]) {
        this.set('visible', true);
        this.set('slotId', siteSettings["adsense_ad_slot_".concat(slot)]);

        var _this$getProperties = this.getProperties('width', 'height'),
            width = _this$getProperties.width,
            height = _this$getProperties.height;

        this.set('adStyle', "display:inline-block; width:".concat(width, "px; height:").concat(height, "px;").htmlSafe());
      }
    },
    didInsertElement: function didInsertElement() {
      this._super();

      if (this.get('visible')) {
        (0, _loadScript.default)("//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js").then(function () {
          var adsbygoogle = window.adsbygoogle || [];
          adsbygoogle.push({});
        });
      }
    }
  });

  _exports.default = _default;
});
Ember.TEMPLATES["javascripts/components/adsense-block"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"visible\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[\"adsense adsense_\",[28,\"unbound\",[[24,[\"slotTrim\"]]],null]]]],[8],[0,\"\\n    \"],[7,\"ins\",true],[10,\"class\",\"adsbygoogle\"],[11,\"style\",[22,\"adStyle\"]],[11,\"data-ad-client\",[29,[[28,\"unbound\",[[24,[\"publisherCode\"]]],null]]]],[11,\"data-ad-slot\",[29,[[28,\"unbound\",[[24,[\"slotId\"]]],null]]]],[8],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/templates/components/adsense-block"}});
Ember.TEMPLATES["javascripts/connectors/discovery-list-container-top/adsense"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"site\",\"mobileView\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"adsense-block\",null,[[\"width\",\"height\",\"slot\"],[\"320\",\"50\",\"topiclist_top_mobile\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"adsense-block\",null,[[\"width\",\"height\",\"slot\"],[\"728\",\"90\",\"topiclist_top\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/templates/connectors/discovery-list-container-top/adsense"}});
Ember.TEMPLATES["javascripts/connectors/topic-above-post-stream/adsense"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"site\",\"mobileView\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"adsense-block\",null,[[\"width\",\"height\",\"slot\"],[\"320\",\"50\",\"topic_top_mobile\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"adsense-block\",null,[[\"width\",\"height\",\"slot\"],[\"728\",\"90\",\"topic_top\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/templates/connectors/topic-above-post-stream/adsense"}});
Ember.TEMPLATES["javascripts/connectors/topic-above-suggested/adsense"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"site\",\"mobileView\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"adsense-block\",null,[[\"width\",\"height\",\"slot\"],[\"320\",\"50\",\"topic_bottom\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"adsense-block\",null,[[\"width\",\"height\",\"slot\"],[\"728\",\"90\",\"topic_bottom\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/templates/connectors/topic-above-suggested/adsense"}});
define("discourse/plugins/Discourse Adsense/initializers/adsense", ["exports", "discourse/lib/plugin-api", "discourse/lib/page-tracker"], function (_exports, _pluginApi, _pageTracker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function __push() {
    var i = $('.adsense').length;
    var j = $('.adsense .adsbygoogle ins ins').length;
    $('ins.adsbygoogle').each(function () {
      if ($(this).html() === '') {
        window.adsbygoogle.push({});
      }
    });

    if (i > j) {
      window.setTimeout(__push, 300);
    }
  }

  function __reload_gads() {
    var ads = document.getElementById("adsense_loader");

    if (ads) {
      // clear the old element and its state
      //ads.remove();
      ads.parentNode.removeChild(ads);

      for (var key in window) {
        if (key.indexOf("google") !== -1) {
          window[key] = undefined;
        }
      }
    }

    window.adsbygoogle = [];
    var ga = document.createElement('script');
    ga.type = 'text/javascript';
    ga.async = true;
    ga.id = "adsense_loader";
    ga.src = '//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(ga, s);
    window.setTimeout(__push, 200);
  }

  function oldPluginCode() {
    _pageTracker.default.current().on('change', __reload_gads);
  }

  function initializeAdsense(api) {
    api.onPageChange(__reload_gads);
  }

  var _default = {
    name: "apply-adsense",
    initialize: function initialize(container) {
      var siteSettings = container.lookup('site-settings:main');
      var publisherCode = (siteSettings.adsense_publisher_code || '').trim();

      if (publisherCode.length) {
        (0, _pluginApi.withPluginApi)('0.1', initializeAdsense, {
          noApi: oldPluginCode
        });
      }
    }
  };
  _exports.default = _default;
});

